import Cookies from "js-cookie";
import { Redirect } from "./Utils";
import { useEffect, useState } from "react";
import * as $ from "jquery";

export default function Buy() {

    const [products, setProducts] = useState([{ id: 0, name: "", image: "", oldPrice: 0, price: 0 }])

    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }
        if (typeof Cookies.get("userId") !== "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/products",
                    method: "get",
                }
            ).done(function (data: { id: number, name: string, image: string, oldPrice: number, price: number }[]) {
                setProducts(data);
            })
        }
    }, [])
    return (
        <>
            <div className={"buy"}>
                <div className={"buy-left"}>
                    <div className={"buy-box-own-design"}><span>Hast du ein eigenes Design?</span></div>
                    <div className={"buy-box-consulting"}>
                        <span>Vereine einen Termin für eine persönliche Beratung.</span>
                        <img className={"bux-box-consulting-image"} src={"/calendar.png"} alt={""} />
                        <div><Redirect href={"/contact"}
                            content={<span
                                className={"bux-box-consulting-arrange"}>Jetzt vereinbaren</span>} /></div>
                    </div>
                    {/*<div className={"buy-box-own"}>
                        <img className={"buy-box-image-own"} src={"/motiv.png"} alt={""} />
                        <span className={"buy-box-title"}>Customizable T-Shirt</span>
                        <br />
                        <br />
                        <span className={"buy-box-price-own"}>€ 9.99 - € 29.99</span>
                    </div>*/}
                </div>
                <div className={"buy-right"}>
                    {products.map(product => {
                        return <BuyBox id={product.id} name={product.name} oldPrice={product.oldPrice} price={product.price} image={product.image} />
                    })}
                </div>
            </div>
        </>
    )
}


export function BuyBox(props: { id: number, name: string, oldPrice: number, price: number, image: string }) {
    return (
        <div className={"buy-box"}>
            <Redirect href={`/article?id=${props.id}`} content={<img className={"buy-box-image"} src={props.image} alt={""} />} />
            <br />
            <span className={"buy-box-title"}>{props.name}</span>
            <br />
            <br />
            <span className={"buy-box-price"}>€ {props.price / 100}</span>
        </div>
    )
}