import Cookies from "js-cookie"
import { useEffect } from "react"
import * as $ from "jquery";


export default function Contact() {

    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }
    }, [])

    return (
        <div>
            <br/>
            <br/>
            <br/>
            <div className={"contact-box"}>
                <div>
                    <input className={"input-names"} type={"text"} placeholder={"Vorname"}/>
                    <input className={"input-names"} type={"text"} placeholder={"Nachname"}/>
                </div>
                <div>
                    <textarea className={"input-message"} placeholder={"Bitte gib hier dein Anliegen an..."}/>
                </div>
                <div>
                    <input className={"input-mail"} type={"email"} placeholder={"E-Mail"}/>
                </div>
                <div className={"contact-send-button-div"}>
                    <span className={"contact-send-button"}>Absenden</span>
                </div>
            </div>
        </div>
    )
}