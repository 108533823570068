import {BagFill} from "react-bootstrap-icons";
import {Redirect} from "./Utils";

export default function Navbar() {

    

    return (
        <div style={{marginTop: "20px"}}>
            <div className={"nav"}>
                <div className={"left-nav"}>
                    <Redirect href={"/"} content={<img className={"nav-image nav-item"} alt={""} src={"/logo.jpg"}/>}/>
                    <Redirect className={"nav-item nav-item-a"} href={"/"} content={"Home"}/>
                    <Redirect className={"nav-item nav-item-a"} href={"/buy"} content={"Artikel kaufen"}/>
                    <Redirect className={"nav-item nav-item-a"} href={"/contact"} content={"Kontakt"}/>
                    <Redirect className={"nav-item nav-item-a"} href={"/about-us"} content={"Über uns"}/>
                </div>
                <div className={"right-nav"}>
                    <Redirect href={"/cart"} content={<BagFill size={35} className={"nav-icon"}/>}/>
                </div>

            </div>
            <hr className={"nav-hr"}/>
        </div>
    )
}