import {Instagram, Tiktok, Youtube} from "react-bootstrap-icons";
import {Redirect} from "./Utils";

export default function Footer() {
    return (
        <>
            <hr className={"footer-hr"}/>
            <div className={"footer"}>
                <div className={"left-footer"}>
                    <Redirect className={"footer-title footer-item"} href={"/"} content={"Print waves"}/>
                    <Redirect className={"footer-item"} href={"#"} content={<a href="/Impressum.pdf" download>Impressum</a>}/>
                    <Redirect className={"footer-item"} href={"#"} content={<a href="/Datenschutzerklarung.pdf" download>Datenschutz</a>}/>
                </div>
                <div className={"right-footer"}>
                    <Redirect href={"https://www.youtube.com/@PrintWaves"}
                              content={<Youtube size={25} className={"footer-icon"}/>}/>
                    <Redirect href={"https://www.tiktok.com/@printwaves.textil"} content={<Tiktok size={25} className={"footer-icon"}/>}/>
                    <Redirect href={"https://www.instagram.com/printwaves.textil"}
                              content={<Instagram size={25} className={"footer-icon"}/>}/>
                </div>
            </div>
        </>
    )
}