import {NavigateFunction, useNavigate} from "react-router-dom";
import React from "react";

export function navigateTo(path: string, navigate: NavigateFunction) {
    navigate(path)
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

export function Redirect(props: {href: string, content: React.ReactNode, className?: string}) {
    const navigate = useNavigate()
    return (
        <span className={props.className != null ? props.className : ""} style={{cursor: "pointer"}} onClick={() => !props.href.includes("://") ? navigateTo(props.href, navigate) : window.location.href = props.href}>{props.content}</span>
    )
}