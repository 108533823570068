import { useEffect, useState } from "react";
import "./App.css"
import { BuyBox } from "./Buy";
import Cookies from "js-cookie";
import * as $ from "jquery";
import { Star, StarFill } from "react-bootstrap-icons";

export default function Home() {

    const [products, setProducts] = useState([{ id: 0, name: "", image: "", oldPrice: 0, price: 0 }])

    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {

            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }
        if (typeof Cookies.get("userId") !== "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/products",
                    method: "get",
                }
            ).done(function (data: { id: number, name: string, image: string, oldPrice: number, price: number }[]) {
                setProducts(data);
            })
        }
    }, [])

    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className={"home"}>
                <div className={"home-left"}>
                    <div>
                        <span style={{ fontSize: "30px", fontWeight: "bold" }} className={"home-font"}>Erstelle deine eigene Kleidung oder wähle eines unserer einzigartigen Designs aus.</span>
                    </div>
                    <div>
                        <span className={"home-font"} style={{ color: "gray" }}>---</span>
                    </div>
                </div>
                <div className={"home-right"}>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className={"article-ratings"}>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Dr. Dalf</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"} /><StarFill
                            className={"full-star-rating"} /><StarFill className={"full-star-rating"} />
                            <StarFill className={"full-star-rating"} /><StarFill className={"full-star-rating"} /></span>
                        <br />
                    </div>
                    <div className={"rating-details"}>
                        <br />
                        <div className={"rating-description"}>
                            <span>Die drucken auch unter Druck! Außerdem keine Drückeberger in der Firma. Bei der Kundenberatung konnte ich meine Ideen gut asudrücken und sie gut aufdrucken! 🤌</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"article-ratings"}>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Bene LP</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"} /><StarFill
                            className={"full-star-rating"} /><StarFill className={"full-star-rating"} /><StarFill className={"full-star-rating"} /><Star className={"empty-star-rating"} /></span>
                        <br />
                    </div>
                    <div className={"rating-details"}>
                        <br />
                        <div className={"rating-description"}>
                            <span>Sehr guter Support bei Anfrage. Sehr Sympathisch und Freundlich.</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className={"home-partner"}>
                <div className={"home-partner-title"}>
                    <span>Entdecke unsere einzigartigen Designs</span>
                </div>
                <div className={"home-scroll"}>
                    {products.map(product => {
                        return <BuyBox id={product.id} name={product.name} oldPrice={product.oldPrice} price={product.price} image={product.image} />
                    })}
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}