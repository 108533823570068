import Cookies from "js-cookie"
import * as $ from "jquery";
import { useEffect } from "react";

export default function AboutUs() {

    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }
    }, [])

    
    return (
        <div className={"about-us"}>
            <div className={"about-us-start-box"}>
                <div className={"about-us-start-box-left"}>
                    <img className={"about-us-start-box-image"} src={"/makerspace.png"} alt={""}/>
                </div>
                <div className={"about-us-start-box-right"}>
                    <div className={"about-us-start-box-title"}>
                        <span>Erfahre hier mehr über uns</span>
                    </div>
                    <br/>
                    <div>
                        <span>Wir sind eine Schülerfirma des P-Seminars vom Gymnasium Wertingen und spezialisieren uns auf Textildruck, vor allem auf den Druck von t-shirts.</span>
                    </div>
                </div>
            </div>

            <div className={"about-us-steps"}>
                <div className={"about-us-steps-box"}>
                    <div className={"about-us-steps-box-title"}>
                        <span>Unser T-Shirt-Druckprozess – Qualität, die man sehen und fühlen kann</span>
                    </div>
                    <div className={"about-us-steps-box-step"}>
                        <div className={"about-us-steps-box-step-left"}>
                        <div className={"about-us-steps-box-step-title"}>
                            <span>1. Schritt (Designentwicklung)</span>
                        </div>
                        <div className={"about-us-steps-box-step-description"}>
                            <span>Jedes T-Shirt beginnt mit einer Idee. Unsere Designabteilung arbeitet eng mit Kunden zusammen, um maßgeschneiderte oder ausgewählte Vorlagen zu digitalisieren. Mithilfe modernster Grafiksoftware sorgen wir dafür, dass jedes Detail perfekt umgesetzt wird.</span>
                        </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className={"about-us-steps-box-step"}>
                        <div className={"about-us-steps-box-step-left"}>
                        <div className={"about-us-steps-box-step-title"}>
                            <span>2. Schritt (Auswahl der Drucktechnik)</span>
                        </div>
                        <div className={"about-us-steps-box-step-description"}>
                            <span>Um höchste Qualität und Langlebigkeit zu gewährleisten, nutzen wir fortschrittliche Drucktechniken wie Siebdruck, Digitaldirektdruck und Transferdruck. Die Wahl der Methode richtet sich nach dem jeweiligen Design und den spezifischen Anforderungen des Kunden.</span>
                        </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className={"about-us-steps-box-step"}>
                        <div className={"about-us-steps-box-step-left"}>
                        <div className={"about-us-steps-box-step-title"}>
                            <span>3. Schritt (Materialbeschaffung)</span>
                        </div>
                        <div className={"about-us-steps-box-step-description"}>
                            <span>Wir setzen auf hochwertige Materialien und beziehen unsere T-Shirts von vertrauenswürdigen Lieferanten. Die Textilien werden sorgfältig ausgewählt, um Komfort und Haltbarkeit zu garantieren.</span>
                        </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className={"about-us-steps-box-step"}>
                        <div className={"about-us-steps-box-step-left"}>
                        <div className={"about-us-steps-box-step-title"}>
                            <span>4. Schritt (Druckvorbereitung)</span>
                        </div>
                        <div className={"about-us-steps-box-step-description"}>
                            <span>Bevor wir drucken, bereiten unsere Fachkräfte jedes T-Shirt vor. Sie sorgen dafür, dass der Stoff glatt und optimal ausgerichtet ist, um perfekte Ergebnisse zu erzielen.</span>
                        </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className={"about-us-steps-box-step"}>
                        <div className={"about-us-steps-box-step-left"}>
                        <div className={"about-us-steps-box-step-title"}>
                            <span>5. Schritt (Drucken und Nachbearbeitung)</span>
                        </div>
                        <div className={"about-us-steps-box-step-description"}>
                            <span>Der Druck wird mit Präzision ausgeführt. Nach dem Druckvorgang durchläuft jedes T-Shirt eine Qualitätskontrolle und wird einer Hitzebehandlung unterzogen. Dies fixiert die Farbe und macht das Produkt waschfest.</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}