import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Dash, Plus, Trash } from "react-bootstrap-icons";
import * as $ from "jquery";

export default function Cart() {

    const [change, setChange] = useState(0)
    const [cartItems, setCartItems] = useState([{ id: 0, name: "", image: "", oldPrice: 0, price: 0, size: "", amount: 0 }])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {

            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }

        if (typeof Cookies.get("userId") !== "undefined") {

            const newCartItems: { id: number, name: string, image: string, oldPrice: number, price: number, size: string, amount: number }[] = []
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/cart?userId=" + Cookies.get("userId"),
                    method: "get",
                    async: false
                }
            ).done(function (data: { id: number, amount: number, size: string, productId: number }[]) {
                data.map(item => {
                    $.ajax(
                        {
                            url: "https://api.printwaves.store/test/product?id=" + item.productId,
                            method: "get",
                            async: false
                        }
                    ).done(function (data2: { id: number, name: string, image: string, oldPrice: number, price: number }) {
                        newCartItems.push({ id: item.id, name: data2.name, image: data2.image, oldPrice: data2.oldPrice, price: data2.price, size: item.size, amount: item.amount })

                    })
                    return null;
                })
            })
            setCartItems(newCartItems)
            let price = 0;
            for (let item in newCartItems) {
                price += newCartItems[item].price * newCartItems[item].amount
            }
            setTotal(price)
        }
    }, [])


    return (
        <div>
            {cartItems.map(item => {
                return item.amount === 0 ? null :
                    <CartItem id={item.id} image={item.image} name={item.name} size={item.size} amount={item.amount} oldPrice={item.oldPrice} price={item.price} func={() => window.location.href = "https://printwaves.store/cart"} />
            })}
            <div className={"cart-buy-button"}>
                <span>Jetzt kaufen ({total / 100}€)</span>
            </div>
        </div>
    )
}

function CartItem(props: { id: number, image: string, name: string, size: string, amount: number, oldPrice: number, price: number, func: () => void }) {
    const [amount, setAmount] = useState(props.amount)
    useEffect(() => {

    }, [])
    return (
        <div className={"cart-item"}>
            <div className={"cart-item-image-div"}>
                <img className={"cart-item-image"} src={props.image} alt={""} />
            </div>
            <span>{props.name}</span>
            <span>Größe: {props.size}</span>
            <div className={"cart-item-amount"}>
                <div className={"cart-item-amount-button"} onClick={() => {
                    if (amount === 10)
                        return

                    $.ajax(
                        {
                            url: "https://api.printwaves.store/test/cart/amount?id=" + props.id + "&amount=" + (amount + 1),
                            method: "get",
                            async: false
                        }
                    ).done(function (data: { id: number, amount: number, size: string, productId: number }[]) {
                        setAmount(amount + 1)
                        props.func()
                    })


                }}>

                    <Plus />
                </div>
                <div className={"cart-item-amount-number"}>
                    <span>{amount}</span>
                </div>
                <div className={"cart-item-amount-button"} onClick={() => {

                    if (amount === 1)
                        $.ajax(
                            {
                                url: "https://api.printwaves.store/test/cart/remove?id=" + props.id,
                                method: "get",
                                async: false
                            }
                        ).done(function (data: { id: number, amount: number, size: string, productId: number }[]) {

                        }
                        )

                    $.ajax(
                        {
                            url: "https://api.printwaves.store/test/cart/amount?id=" + props.id + "&amount=" + (amount - 1),
                            method: "get",
                            async: false
                        }
                    ).done(function (data: { id: number, amount: number, size: string, productId: number }[]) {
                        setAmount(amount - 1)
                        props.func()
                    })
                    props.func()
                }}>
                    <Dash />
                </div>
            </div>
            <div className={"cart-item-prices"}>
                <span className={"cart-item-price"}>{props.price / 100} €</span>
            </div>
            <span className={"cart-item-remove"} onClick={() => {

                $.ajax(
                    {
                        url: "https://api.printwaves.store/test/cart/remove?id=" + props.id,
                        method: "get",
                        async: false
                    }
                ).done(function (data: { id: number, amount: number, size: string, productId: number }[]) {

                })
                props.func();
            }}>
                <Trash color={"red"} size={20} />
            </span>
        </div>
    )
}