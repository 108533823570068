import Cookies from "js-cookie";
import { BuyBox } from "./Buy";
import { Star, StarFill } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import * as $ from "jquery";

export default function Article() {

    const queryParameters = new URLSearchParams(window.location.search)

    const [products, setProducts] = useState([{ id: 0, name: "", image: "", oldPrice: 0, price: 0 }])

    const [sellProduct, setSellProduct] = useState({ id: 0, description: "", imageUrls: "", sizes: "", name: "", image: "", oldPrice: 0, price: 0 })
    const [activeImage, setActiveImage] = useState("")
    const [activeSize, setActiveSize] = useState("")
    useEffect(() => {
        if (typeof Cookies.get("userId") === "undefined") {
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/create",
                    method: "get",
                }
            ).done(function (data: number) {
                Cookies.set("userId", "" + data)
            })
        }
        if (typeof Cookies.get("userId") !== "undefined") {
            
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/products",
                    method: "get",
                }
            ).done(function (data: { id: number, name: string, image: string, oldPrice: number, price: number }[]) {
                setProducts(data);
            })
            $.ajax(
                {
                    url: "https://api.printwaves.store/test/product?id=" + queryParameters.get("id"),
                    method: "get",
                }
            ).done(function (data: { id: number, description: string, imageUrls: string, sizes: string, name: string, image: string, oldPrice: number, price: number }) {
                setActiveImage(data.image)

                const a = data.imageUrls.split(";")
                a.unshift(data.image)
                const b = data
                b.imageUrls = a.join(";")

                setActiveSize(b.sizes.split(",")[0])
                setSellProduct(b)
            })
        }
    }, [])

    return (
        <div>
            <div className={"article"}>
                <div className={"product-images"}>
                    <img className={"product-images-image"} src={activeImage} alt={""} />
                    <div className={"subimages"}>
                        {sellProduct.imageUrls.split(";").map(image => {
                            return image === activeImage ? <img className={"product-images-subimage subimage-active"} onClick={() => setActiveImage(image)} src={image} alt={""} /> : <img onClick={() => setActiveImage(image)} className={"product-images-subimage"} src={image} alt={""} />
                        })}
                    </div>

                </div>
                <div className={"product-details"}>
                    <div className={"product-title"}><span>{sellProduct.name}</span></div>
                    <br />
                    <div className={"product-prices"}>
                        <span className={"product-price"}>{sellProduct.price / 100}€</span>
                    </div>
                    <br />
                    <div className={"product-description"}><span>{sellProduct.description}</span>
                    </div>

                </div>
            </div>
            <div className={"product-buy-phase"}>
                <div className={"product-buy-rest-left"}>

                </div>
                <div>
                    <div className={"product-sizedropdown"}>
                        <label>Size: </label>
                        <select name="size" id="size" value={activeSize} onChange={(target) => setActiveSize(target.target.value)}>
                            {sellProduct.sizes.split(",").map((size: string) => {
                                return <option value={size}>{size}</option>
                            })}
                        </select>
                    </div>
                    <br />
                    <div className={"product-buy-button"} onClick={() => {
                        $.ajax(
                            {
                                url: "https://api.printwaves.store/test/cart/add?userId=" + Cookies.get("userId") + "&productId=" + sellProduct.id + "&size=" + activeSize,
                                method: "get",
                            }
                        ).done(function () {
                            window.location.href = "https://printwaves.store/cart"
                        })
                    }}>
                        <span>Add to cart</span>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            {/*<div className={"article-ratings"}>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Gustav</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"}/><StarFill
                            className={"full-star-rating"}/><StarFill className={"full-star-rating"}/><Star
                            className={"empty-star-rating"}/><Star className={"empty-star-rating"}/></span>
                        <br/>
                    </div>
                    <div className={"rating-details"}>
                        <div className={"rating-title"}>
                            <span>Echt scheiß Produkt</span>
                        </div>
                        <br/>
                        <div className={"rating-description"}>
                            <span>So ein geiles Produkt würde immer wieder loch geben</span>
                        </div>
                    </div>
                </div>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Gustav</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"}/><StarFill
                            className={"full-star-rating"}/><StarFill className={"full-star-rating"}/><Star
                            className={"empty-star-rating"}/><Star className={"empty-star-rating"}/></span>
                        <br/>
                    </div>
                    <div className={"rating-details"}>
                        <div className={"rating-title"}>
                            <span>Echt scheiß Produkt</span>
                        </div>
                        <br/>
                        <div className={"rating-description"}>
                            <span>So ein geiles Produkt würde immer wieder loch geben</span>
                        </div>
                    </div>
                </div>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Gustav</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"}/><StarFill
                            className={"full-star-rating"}/><StarFill className={"full-star-rating"}/><Star
                            className={"empty-star-rating"}/><Star className={"empty-star-rating"}/></span>
                        <br/>
                    </div>
                    <div className={"rating-details"}>
                        <div className={"rating-title"}>
                            <span>Echt scheiß Produkt</span>
                        </div>
                        <br/>
                        <div className={"rating-description"}>
                            <span>So ein geiles Produkt würde immer wieder loch geben</span>
                        </div>
                    </div>
                </div>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Gustav</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"}/><StarFill
                            className={"full-star-rating"}/><StarFill className={"full-star-rating"}/><Star
                            className={"empty-star-rating"}/><Star className={"empty-star-rating"}/></span>
                        <br/>
                    </div>
                    <div className={"rating-details"}>
                        <div className={"rating-title"}>
                            <span>Echt scheiß Produkt</span>
                        </div>
                        <br/>
                        <div className={"rating-description"}>
                            <span>So ein geiles Produkt würde immer wieder loch geben</span>
                        </div>
                    </div>
                </div>
                <div className={"rating"}>
                    <div className={"rating-profile"}>
                        <span className={"rater-name"}>Gustav</span>
                        <span className={"rating-stars"}><StarFill className={"full-star-rating"}/><StarFill
                            className={"full-star-rating"}/><StarFill className={"full-star-rating"}/><Star
                            className={"empty-star-rating"}/><Star className={"empty-star-rating"}/></span>
                        <br/>
                    </div>
                    <div className={"rating-details"}>
                        <div className={"rating-title"}>
                            <span>Echt scheiß Produkt</span>
                        </div>
                        <br/>
                        <div className={"rating-description"}>
                            <span>So ein geiles Produkt würde immer wieder loch geben</span>
                        </div>
                    </div>
                </div>
            </div>*/}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className={"home-partner"}>
                <div className={"home-partner-title"}>
                    <span>Entdecke andere tolle Artikel</span>
                </div>
                <div className={"home-scroll"}>
                    {products.map(product => {
                        return <BuyBox id={product.id} name={product.name} oldPrice={product.oldPrice} price={product.price} image={product.image} />
                    })}
                </div>
            </div>
        </div>
    )
}